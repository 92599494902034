import React from "react"
import FormattedText from "../components/FormattedText"
import Layout from "../templates/Layout"
import "react-unique-key" 

import { graphql } from "gatsby"

const Infopage = ({ data: { prismicInfopages } }) => {
  const { data } = prismicInfopages;


  return (
    <Layout page={prismicInfopages}>
      <div>
        <main className="container">
          <div className="mt-32 mb-4 lg:mb-16">
            <FormattedText text={data.title.html} />
          </div>
          <div className="py-16 lg:w-2/3">
            <FormattedText text={data.main_body.html} />
          </div>
        </main>
      </div>
    </Layout>
  )
}

export default Infopage
export const pageQuery = graphql`
  query InfopagesBySlug($uid: String!) {
    prismicInfopages(uid: { eq: $uid }) {
      data {
        title {
          html
        }
        sub_title {
          html
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        main_body {
          html
        }
      }
    }
  }
`